import * as React from 'react';
import '../tw.css';

export interface ImgCenteredCaptionProp{
    children:any;
    imageSrc:string;
    alt?:string;
}

export default function ImgCenteredCaption(props:ImgCenteredCaptionProp){
    const [isLoad,setIsLoad] = React.useState<boolean>(false);
    React.useEffect(()=>{
        setTimeout(() => {
            setIsLoad(true);
        }, 1000);
    },[]);
    return <div className={`relative text-center transition-opacity ease-in-out duration-1000 ${isLoad ? "opacity-100" : "opacity-0"}`}>
        <img src={props.imageSrc} className="opacity-40 w-full z-0" alt={props.alt}></img>
        <div className='absolute w-full h-full top-0 flex items-center justify-center z-0'>
            <div className='bg-zinc-500/60 rounded-lg p-5 mx-10 sm:mx-10 md:mx-24 lg:mx-48'>
                {props.children}
            </div>
        </div>
    </div>
}