import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Card from "../Components/Card";
import { AmazonSmileIcon, CopyIcon, LinkIcon, StewardshipIcon } from "../Components/Icons";
import ImgCenteredCaption from "../Components/ImgCenteredCaption";
import Brand from "../Const/Brand.json";
import { copyToClipboard, GetBodyFontClass, GetHeaderFontClass } from "../Helper/Helper";

export default function SupportUsPage(){
    const { t } = useTranslation();
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('supportUs.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('supportUs.subTitle')}</p>
        </ImgCenteredCaption>
        {/* Bank Transfer */}
        <div className="flex justify-center mt-5 mx-3 md:mx-10">
            <Card>
                <div className='flex flex-col md:flex-row items-center'>
                    <div className="text-center text-green-600 md:mr-10 p-5 md:mb-10 md:indent-8 drop-shadow-md">
                        <FontAwesomeIcon icon={solid('building-columns')} size="8x"></FontAwesomeIcon>
                        <h2 className={`text-center mt-8 ${GetHeaderFontClass()}`}> {t("supportUs.bankTransfer.title")}</h2>
                    </div>
                    <div className={`flex-1 justify-center text-center ${GetBodyFontClass()}`}>
                        <h3 className='text-center mt-3'>{t(`supportUs.bankTransfer.description`)}</h3>
                        <div className="flex justify-center mt-5">
                            <table>
                                <tbody>
                                    {/* Name */}
                                    <tr>
                                        <td className="text-right">{t(`supportUs.bankTransfer.accountNameLabel`)}:</td>
                                        <td className="ml-2 underline text-blue-600 cursor-pointer text-left" onClick={()=>copyToClipboard(Brand.donation.bankTransfer.accountName)}>
                                            {Brand.donation.bankTransfer.accountName}<CopyIcon className="ml-3" text={Brand.donation.bankTransfer.accountName}></CopyIcon>
                                        </td>
                                    </tr>
                                    {/* Sort Code */}
                                    <tr>
                                        <td className="text-right">{t(`supportUs.bankTransfer.sortcodeLabel`)}:</td>
                                        <td className="ml-2 underline text-blue-600 cursor-pointer text-left" onClick={()=>copyToClipboard(Brand.donation.bankTransfer.sortcode)}>
                                            {Brand.donation.bankTransfer.sortcode}<CopyIcon className="ml-3" text={Brand.donation.bankTransfer.sortcode}></CopyIcon>
                                        </td>
                                    </tr>
                                    {/* Account Number */}
                                    <tr>
                                        <td className="text-right">{t(`supportUs.bankTransfer.accountNumberLabel`)}:</td>
                                        <td className="ml-2 underline text-blue-600 cursor-pointer text-left" onClick={()=>copyToClipboard(Brand.donation.bankTransfer.accountNumber)}>
                                            {Brand.donation.bankTransfer.accountNumber}<CopyIcon className="ml-3" text={Brand.donation.bankTransfer.accountNumber}></CopyIcon>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p className='text-center mt-3 text-red-400'>**{t(`supportUs.bankTransfer.giftAid`)}**</p>
                    </div>
                </div>
            </Card>
        </div>
        {/* Stewardship */}
        <div className="flex justify-center mt-5 mx-3 md:mx-10">
            <Card>
                <div className='flex flex-col md:flex-row-reverse items-center'>
                    <div className="text-green-600 md:mr-10 p-5 md:mb-10 drop-shadow-md justify-center flex flex-col">
                        <div className="flex justify-center">
                            <StewardshipIcon className=""></StewardshipIcon>
                        </div>
                        <h2 className={`text-center mt-8 ${GetHeaderFontClass()}`} style={{color:"#ffc300"}}> {t("supportUs.stewardship.title")}</h2>
                    </div>
                    <div className={`flex-1 justify-center text-center ${GetBodyFontClass()}`}>
                        <h3 className='text-center mt-3'>{t(`supportUs.stewardship.description`)}</h3>
                        <p>
                            <a href={Brand.donation.stewardship} className="underline text-blue-600" target="_blank" rel="noreferrer">{t(`supportUs.stewardship.supportUs`)}</a> <LinkIcon href={Brand.donation.stewardship} className="text-base"></LinkIcon>
                        </p>
                        <p className='text-center mt-3 text-red-400'>**{t(`supportUs.stewardship.notice`)}**</p>
                    </div>
                </div>
            </Card>
        </div>
        {/* Amazon smile (Ended) */}
        {/* <div className="flex justify-center mt-5 mx-3 md:mx-10">
            <Card>
                <div className='flex flex-col md:flex-row items-center'>
                    <div className="text-green-600 md:mr-10 p-5 md:mb-10 drop-shadow-md justify-center flex flex-col">
                        <div className="flex justify-center">
                            <AmazonSmileIcon></AmazonSmileIcon>
                        </div>
                        <h2 className={`text-center mt-8 text-black ${GetHeaderFontClass()}`}> {t("supportUs.amazonSmile.title")}</h2>
                    </div>
                    <div className={`flex-1 justify-center text-center ${GetBodyFontClass()}`}>
                        <h3 className='text-center mt-3'>{t(`supportUs.amazonSmile.description`)}</h3>
                        <p>
                            <a href={Brand.donation.amazonSmile} className="underline text-blue-600" target="_blank" rel="noreferrer">{t('supportUs.amazonSmile.register')}</a> <LinkIcon href={Brand.donation.amazonSmile} className="text-base"></LinkIcon>
                        </p>
                        <p className='text-center mt-3 text-red-400'>**{t(`supportUs.amazonSmile.notice`).replace('{0}',"smile.amazon.com")}**</p>
                    </div>
                </div>
            </Card>
        </div> */}
    </div>
}