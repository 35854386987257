import * as React from 'react';
import '../tw.css';
import { useTranslation } from "react-i18next";
import ImgCenteredCaption from '../Components/ImgCenteredCaption';
import ImageButton from '../Components/ImageButton';
import { useHistory } from 'react-router-dom';
import { EnglishIcon, MandarinIcon } from '../Components/Icons';
import { Page } from '../Const/Page';
import { GetBodyFontClass, GetHeaderFontClass } from '../Helper/Helper';

export default function StudentMinistryPage(){
    const history = useHistory();
    const { t } = useTranslation();
    return <div className='mb-10'>
        <ImgCenteredCaption imageSrc={"https://lccccompany.blob.core.windows.net/public/churchBuilding.jpg"} alt="Church Building">
            <h1 className={`md:text-6xl text-2xl text-center text-white ${GetHeaderFontClass()}`}>{t('studentministry.title')}</h1>
            <p className={`md:text-2xl text-lg text-center text-white mt-5 ${GetBodyFontClass()}`}>{t('studentministry.subTitle')}</p>
        </ImgCenteredCaption>
        <div className='flex flex-wrap justify-center mt-5'>
            <ImageButton className={`w-2/3 md:w-1/3 mx-5 md:mx-3 ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/emhall.jpg' onClick={()=>history.push(Page.youngAdultFellowship)}>
                <EnglishIcon className='!text-sky-300'></EnglishIcon>{t('studentministry.buttons.yaf.title')}
            </ImageButton>
            <ImageButton className={`w-2/3 md:w-1/3 mx-5 md:mx-3 mt-3 md:mt-0 ${GetHeaderFontClass()}`} imageSrc='https://lccccompany.blob.core.windows.net/public/mainhall.jpg'onClick={()=>history.push(Page.mandarinStudentFellowship)}>
                <MandarinIcon className='!text-rose-500'></MandarinIcon>{t('studentministry.buttons.msf.title')}
            </ImageButton>
        </div>
    </div>
}
