import { BackToTopId } from "../Components/AppBar";
import Language from "../Const/Language";
import i18n from "../Local/i18nSetup";

export const nameof = <T>(name: keyof T) => name;

export const isASCII = (str:string) => /^[\x00-\x7F]*$/.test(str);

//Random color generator
export const stringToColor = (string: string) => {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
}
  
//Avatar inital generator and color generator 
export const stringAvatar = (name: string) => {
    return {
      style: {
        backgroundColor: stringToColor(name),
      },
      children: name.split(' ').length>1 ? name.split(' ').slice(0,2).map(x=>x[0].toUpperCase()) : name.slice(0,2).toUpperCase(),
    };
}



export const GetHeaderFontClass = (customSizeClasses?:string, language?:Language) => {
  let currentLang = language ? language : i18n.language;
  let result = customSizeClasses ? `${customSizeClasses} ` : "text-xl md:text-2xl ";
  return currentLang == Language.English.Code ? result+"font-en_standard" : currentLang == Language.TraditionalChinese.Code ? result+"font-cn_standard" : result+"font-cn_standard";
}

export const GetBodyFontClass = (customSizeClasses?:string, language?:Language) => {
  let currentLang = language ? language : i18n.language;
  let result = customSizeClasses ? `${customSizeClasses} ` : "text-xl md:text-2xl ";
  return currentLang == Language.English.Code ? result+"font-en_standard" : currentLang == Language.TraditionalChinese.Code ? result+"font-cn_standard" : result+"font-cn_standard";
}

export const fallbackCopyTextToClipboard = (text:string) => {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  
  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand('copy');
    var msg = successful ? 'successful' : 'unsuccessful';
    console.log('Fallback: Copying text command was ' + msg);
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export const copyToClipboard = (text:string) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!');
  }, function(err) {
    console.error('Async: Could not copy text: ', err);
  });
}

export const GotoTop = (event: React.MouseEvent<HTMLDivElement>) => {
  const anchor = (
    (event.target as HTMLDivElement).ownerDocument || document
  ).querySelector(`#${BackToTopId}`);

  if (anchor) {
    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }
};